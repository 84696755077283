import $ from 'jquery';
import '@/scripts/helpers/polyfills';
import 'slick-carousel/slick/slick';
import lazyframe from 'lazyframe';
import { bindJQuery } from '@/scripts/helpers/library';
import { accordion } from '../modules/containers/accordion/index';
import { BREAKPOINTS } from '@/scripts/helpers/constants';

const { TABLET, DESKTOP } = BREAKPOINTS;

bindJQuery();

const arrowNext = `
<button class="next">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.10164 13.7071C6.71111 14.0976 6.07795 14.0976 5.68742 13.7071C5.2969 13.3166 5.2969 12.6834 5.68742 12.2929L9.98032 8L1 8C0.447716 8 1.12735e-06 7.55228 1.22392e-06 7C1.32048e-06 6.44772 0.447717 6 1 6L9.98032 6L5.68743 1.70711C5.2969 1.31658 5.2969 0.683418 5.68743 0.292894C6.07795 -0.0976304 6.71112 -0.0976303 7.10164 0.292895L13.101 6.29228L13.1016 6.2929C13.1038 6.29507 13.106 6.29726 13.1081 6.29945C13.2983 6.4931 13.3938 6.74494 13.3945 6.997M13.1081 7.70055C13.2008 7.60621 13.271 7.49805 13.3187 7.38279C13.2692 7.50212 13.1972 7.6098 13.1081 7.70055ZM7.10164 13.7071L13.101 7.70772L7.10164 13.7071Z" />
  </svg>
</button>
`;

const arrowPrev = `
<button class="prev">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.89836 0.292893C7.28888 -0.0976311 7.92205 -0.0976311 8.31257 0.292893C8.7031 0.683417 8.7031 1.31658 8.31257 1.70711L4.01968 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H4.01968L8.31257 12.2929C8.7031 12.6834 8.7031 13.3166 8.31257 13.7071C7.92205 14.0976 7.28888 14.0976 6.89836 13.7071L0.898972 7.70772L0.898358 7.70711C0.89618 7.70493 0.894014 7.70274 0.89186 7.70055C0.701684 7.5069 0.60622 7.25507 0.605469 7.003M0.89186 6.29945C0.799207 6.3938 0.729035 6.50195 0.681344 6.61722C0.730829 6.49788 0.802756 6.39021 0.89186 6.29945ZM6.89836 0.292893L0.898972 6.29228L6.89836 0.292893Z" />
  </svg>
</button>
`;

$('.aside-nav-title').on('click', function() {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $(this).next().fadeOut(100);
  } else {
    $(this).addClass('is-active');
    $(this).next().fadeIn(100);
  }
});

$('.aside-nav__link').on('click', function() {
  const DESKTOP = 960;

  if ($(window).width() < DESKTOP) {
    $(this).closest('.aside-nav').fadeOut(100);
    $(this).closest('.aside-nav').prev().removeClass('is-active').text($(this).text());
  }
});

$(document).ready(() => {
  accordion();

  lazyframe($('.video-player__lazyframe'), {
    onAppend: iframe => {
      $(iframe).attr('allow', 'autoplay');
    },
  });

  $('.js-related-content-slider').slick({
    arrows: true,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: (TABLET - 1),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: (DESKTOP - 1),
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  $('.js-related-search-slider').slick({
    arrows: true,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: (TABLET - 1),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: (DESKTOP - 1),
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });
});
